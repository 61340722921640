<template>
  <div>
    <ErrorDialog
      v-if="error"
      :error="error"
      :message="errorMessage"
      @exitError="extiError"
    />
    <v-card class="my-3" elevation="0">
      <v-row class="mx-0">
        <v-col cols="12">
          <v-text-field
            v-model="name"
            :label="$t('model.prop.fullname')"
            :placeholder="$t('model.prop.fullname')"
            color="green"
            filled
            rounded
            dense
          ></v-text-field>
          <v-btn
            color="green lighten-1"
            large
            @click="next"
            :loading="api.isLoading"
            block
            rounded
            class="text-h6"
            style="text-transform: capitalize"
          >
            {{ $t("action.next") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
let click_sfx_1 = new Audio(require("@/assets/sounds/sfx_click_5.wav"));
let click_sfx_2 = new Audio(require("@/assets/sounds/sfx_click_6.wav"));
export default {
  components: {
    ErrorDialog: () =>
      import(
        /* webpackChunkName: "component-error-dialog" */ "@/components/ErrorDialog.vue"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
  }),
  props: [
    //
    "classroomId",
  ],
  data: () => ({
    error: false,
    errorMessage: null,
    name: null,
    Player: {
      avatar: null,
      password: null,
    },
    authData: {},
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  created() {
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/student/walkin";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      console.log(e);
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.authData.Player = this.Player;
      this.authData.User = resp;
      this.authData.User.type = "child";
      this.$store.commit("updateAuth", this.authData);

      this.$router.push({ name: "PageAccessOnboardingAvatar" });
    };
  },
  watch: {
    name: function (val) {
      this.name = val;
    },
  },
  mounted() {
    if (this.auth) {
      this.authData = this.$_.cloneDeep(this.auth);
    }
  },
  methods: {
    //
    next() {
      if (this.name) {
        click_sfx_1.play();
        click_sfx_1.volume =
          this.settings.audio.sfx * this.settings.audio.master;
        this.api.params = this.$_.clone({
          classroomId: this.classroomId,
          name: this.name,
        });
        this.$api.fetch(this.api);
      } else {
        click_sfx_2.play();
        click_sfx_2.volume =
          this.settings.audio.sfx * this.settings.audio.master;
        this.errorMessage = this.$t("string.emptyNameError");
        this.error = true;
      }
    },
    extiError() {
      this.error = false;
    },
  },
};
</script>

<style>
</style>